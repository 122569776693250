<template functionnal>
  <div class="template">
    <div class="template__content">
      <div class="row">
        <div class="col-12">
          <h1>Nos formations | <span class="marker-effect">Cours de Français sur mesure</span></h1>
        </div>
      </div>

      <div class="main-photo row mt-5">
        <img
          src="@/assets/images/06-surmesure.jpeg"
          style="margin-top: -30px;"
          alt="cour de francais visee pro"
        />
      </div>

      <div class="row mb-2">
        <div class="col-12 mt-4">
          <div class="row">
            <h5>Français à visée professionnelle pour entreprise et/ou entreprise d'insertion</h5>
          </div>
          <div class="row">
            <div class="col-12 p-0 font-bold">Public et prérequis</div>
            <div>
              Public salarié ou en démarche d'insertion dans le cadre d'une formation
              professionnelle individuelle (CPF) ou d'un plan de formation à l'initiative de
              l'employeur.
            </div>
          </div>
          <div class="row">
            <div class="col-12 p-0 font-bold">Objectifs</div>
            <div>
              Donner les compétences langagières et pratiques liées à leur métier. <br />
              Développer le savoir-faire et le savoir-être ainsi que les codes sociaux en usage dans
              ce milieu professionnel. <br />
              Apprendre à lire et à écrire pour les personnes en alphabétisation. <br />
              Favoriser leur autonomie dans le travail.
            </div>
          </div>
          <div class="row">
            <div class="col-12 p-0 font-bold">Durée, modalités et tarif</div>
            <div>
              <router-link class="p-0" to="/contact">Demander un devis.</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-photo {
  height: 400px;
  width: 70%;
  margin: auto;
  overflow: hidden;
}

.align-center {
  width: 100%;
  text-align: center;
}

.logos {
  font-size: 1.5rem;
  margin: 2rem 0;

  .images {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      border: 2px solid #333;
      border-radius: 1rem;
      // opacity: 0.8;
      width: 250px;
      &:first-child {
        margin-right: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 830px) {
  .main-photo {
    width: 100%;
    height: auto;
  }
}
</style>
